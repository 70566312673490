<template>
    <v-container class="ma-0 pa-0">
        <v-row
            class="d-flex flex-row flex-nowrap items-center justify-space-between w-100"
            align-content="center"
            justify="space-between"
        >
            <v-col class="ma-0 pa-0 pl-3">
                <v-card color="white" elevation="0" class="pa-3 py-5 d-inline-flex" rounded="lg">
                    <div class="d-inline-flex mr-3">
                        <SimpleSelect
                            :items="courseOptions"
                            :value="currentCourse"
                            :model="currentCourse"
                            :placeholder="$t('diagnoses.inputs.placeholders.chooseCourse')"
                            defaultItemValueField="id"
                            defaultItemNameField="name"
                            innerIcon="mdi-folder"
                            @new-value-selected="(id) => (currentCourse = id)"
                            withLabel
                            :label="$t('diagnoses.inputs.labels.course')"
                            :disabled="loading"
                        />
                    </div>
                    <div class="mr-3">
                        <SimpleSelect
                            :items="getEvalutionTextsWithResults"
                            :value="currentText"
                            :model="currentText"
                            :placeholder="$t('evaluate.inputs.placeholders.chooseEvaluationText')"
                            defaultItemValueField="id"
                            defaultItemNameField="title"
                            innerIcon="mdi-layers"
                            @new-value-selected="(id) => (currentText = id)"
                            withLabel
                            :label="$t('evaluate.inputs.labels.evaluationText')"
                            :disabled="loading || getEvalutionTextsWithResults.length === 0"
                        />
                    </div>
                </v-card>
            </v-col>
            <!-- <v-col class="ma-0 pa-0 d-flex align-center justify-end">
                <v-card
                    elevation="0"
                    rounded="lg"
                    class="pa-3 py-5 d-inline-flex justify-center align-center"
                >
                    <DropdownButton
                        mainIcon="mdi-download"
                        :text="$t('evaluate.actions.export')"
                        :format-options="downloadOptions"
                    />
                </v-card>
            </v-col> -->
        </v-row>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('evaluate.titles.generalInformation') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <AssignedEvaluationsTable
                    :headers="assignedEvaluationsHeaders"
                    :items="currentEvalutionAssignedResultsItems"
                    @on-update="() => {}"
                    @on-stats="() => {}"
                    @on-delete="() => {}"
                    @on-evaluation="() => {}"
                    showDetail
                    showEvaluate
                    :loading="loading"
                />
            </section>
        </v-card>

        <!-- Graphs -->
        <v-row
            class="d-flex flex-row flex-nowrap align-items-start justify-space-between w-100 ma-0 pa-0"
            align-content="center"
            justify="space-between"
        >
            <v-col class="w-100 ma-0 pa-0 mt-5 d-flex align-stretch" cols="12" md="6">
                <v-card
                    color="white"
                    elevation="0"
                    rounded="lg"
                    class="relative w-100 d-flex flex-column align-start justify-center mr-1 pa-5"
                >
                    <h2>{{ $t('evaluate.tables.results.labels.lectureQuality') }}</h2>
                    <v-divider></v-divider>
                    <SimplePieChartGraph
                        :data="readingCategoryData"
                        :show-label="false"
                        seriesName="Calidad lectora"
                    />
                    <v-divider></v-divider>
                    <!-- Labels from the graph -->
                    <v-row
                        class="my-1 d-flex flex-row align-self-center align-start justify-center w-100"
                        align-content="center"
                        justify="center"
                    >
                        <v-col
                            cols="4"
                            class="pa-3 d-flex flex-column align-start w-100"
                            v-for="(readingCategory, i) in readingCategoryData"
                            :key="i"
                        >
                            <div class="d-flex flex-row w-100 align-start">
                                <div
                                    class="rounded-circle d-inline-block ma-0 pa-0 mt-1"
                                    :style="`width: 12px; height: 12px; background-color: ${readingCategory.itemStyle.color}`"
                                ></div>
                                <span class="pl-1 body-2 ma-0 pa-0">{{
                                    readingCategory.name
                                }}</span>
                            </div>
                            <p class="body-1 text-center font-weight-bold">
                                {{ getPercentageOfTotalAmount(readingCategory.value) }}%
                                <span class="font-weight-light">({{ readingCategory.value }})</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col class="ma-0 pa-0 mt-5 d-flex align-stretch" cols="12" md="6">
                <v-card
                    color="white"
                    elevation="0"
                    rounded="lg"
                    class="w-100 d-flex flex-column align-start justify-center ml-1 pa-5"
                >
                    <h2>{{ $t('evaluate.tables.results.labels.readingSpeed') }}</h2>
                    <SimplePieChartGraph
                        :data="readingSpeedResults"
                        :show-label="false"
                        seriesName="Velocidad lectora"
                    />
                    <v-divider></v-divider>
                    <!-- Labels from the graph -->
                    <v-row
                        class="my-1 d-flex flex-row align-self-center align-start justify-center w-90"
                        align-content="center"
                        justify="center"
                    >
                        <v-col
                            cols="4"
                            class="pa-3 d-flex flex-column align-start w-100"
                            v-for="(speedresult, i) in readingSpeedResults"
                            :key="i"
                        >
                            <div class="d-flex flex-row w-100 align-center">
                                <div
                                    class="rounded-circle d-inline-block"
                                    :style="`width: 12px; height: 12px; background-color: ${speedresult.itemStyle.color}`"
                                ></div>
                                <span class="pl-1 body-2">{{ speedresult.name }}</span>
                            </div>
                            <p class="body-1 text-center font-weight-bold">
                                {{ getPercentageOfTotalAmount(speedresult.value) }}%
                                <span class="font-weight-light">({{ speedresult.value }})</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('evaluate.titles.studentList') }}
            </h2>
            <v-divider></v-divider>

            <section class="pa-6">
                <StudentsEvaluationResultsTable
                    :headers="studentListHeaders"
                    :items="studentListItems"
                    :showActions="false"
                    :loading="loading"
                    :items-per-page="10"
                    :hide-default-footer="true"
                />
            </section>
        </v-card>
    </v-container>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import DropdownButton from '@/components/core/buttons/dropdownButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import AssignedEvaluationsTable from '../shared/tables/AssignedEvaluationsTable.vue'
import EvaluationStudentListTable from '../shared/tables/EvaluationStudentList.vue'
import StudentsEvaluationResultsTable from '../shared/tables/StudentsEvaluationResultsTable.vue'
import SimplePieChartGraph from '@/components/core/graphs/simplePieChart/index.vue'
import FluencyServices from '@/services/fluency'
export default {
    name: 'AssignmentsResults',
    components: {
        SimpleButton,
        SimpleSelect,
        AssignedEvaluationsTable,
        EvaluationStudentListTable,
        StudentsEvaluationResultsTable,
        SimplePieChartGraph,
        DropdownButton,
    },
    data() {
        return {
            currentEvaluationResults: null,
            currentCourse: 0,
            currentText: 0,
            optionalCurrentEvaluationId: null,
            courseOptions: [],
            currentEvaluationState: 'ALL',
            evaluationID: null,
            loading: false,
            updateEvaluation: false,
            successUpdate: false,
            selectedEvaluation: undefined,
            assignedEvaluations: [],
            readingCategoryOptions: [
                'FLUENT_READING',
                'SHORT_UNITS_READING',
                'WORD_BY_WORD_READING',
                'SYLLABIC_READING',
                'NO_READER',
            ],
            readingSpeedOptions: [
                'SUPER_FAST',
                'FAST',
                'MEDIUM_HIGH',
                'MEDIUM_LOW',
                'SLOW',
                'SUPER_SLOW',
            ],
            resultsColors: ['#17603C', '#228855', '#3FB16D', '#7ED7A0', '#56C382', '#DAFBE4'],
            courseEvaluationRestulsTexts: [],
            isDownloading: false,
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            userID: (state) => state.userData.user.id,
            assignedEvaluationsState: (state) => state.fluency.assignedEvaluations,
            courseSelected: (state) => state.fluency.currentCourseResultsID,
            evaluationTextSelected: (state) => state.fluency.currentCourseTextResultsID,
            evaluationsTexts: (state) => state.fluency.evaluationsTexts,
        }),
        ...mapGetters({
            evaluationDetail: 'fluency/currentDiagnostic',
        }),
        downloadOptions() {
            return [
                {
                    id: 1,
                    format: 'PDF',
                    text: this.$t('evaluate.actions.exportPdf'),
                    action: this.generatePDF,
                    icon: 'mdi-file-pdf-box',
                },
                {
                    id: 2,
                    format: 'EXCEL',
                    text: this.$t('evaluate.actions.exportExcel'),
                    action: this.generateExcel,
                    icon: 'mdi-file-excel-box',
                },
            ]
        },
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedText'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedTo'),
                    align: 'center',
                    sortable: false,
                    value: 'course_name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'progress_status',
                    width: '240px',
                },
            ]
        },
        assignedEvaluationsItems() {
            let filteredEvaluations = this.assignedEvaluations

            if (this.currentCourse !== 0) {
                filteredEvaluations = filteredEvaluations.filter(
                    (e) => e.course_id === this.currentCourse
                )
            }

            if (this.currentEvaluationState !== 'ALL') {
                filteredEvaluations = filteredEvaluations.filter(
                    (e) => e.status === this.currentEvaluationState
                )
            }

            return filteredEvaluations
        },
        currentEvalutionAssignedResultsItems() {
            if (
                !this.currentEvaluationResults ||
                this.currentEvaluationResults.code === 'EVALUATION_NOT_FOUND'
            )
                return []

            return [this.currentEvaluationResults]
        },
        currentEvaluation() {
            return this.assignedEvaluationsItems[0] || null
        },
        studentListHeaders() {
            return [
                {
                    text: this.$t('evaluate.tables.studentsList.labels.student'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '30%',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.state'),
                    align: 'center',
                    sortable: true,
                    value: 'progress_state',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.correctWordsPerMinute'),
                    align: 'center',
                    sortable: true,
                    value: 'correct_words_per_minute',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.readingSpeed'),
                    align: 'center',
                    sortable: true,
                    value: 'fluency_level',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.readingQuality'),
                    align: 'center',
                    sortable: true,
                    value: 'reading_category',
                    width: '150px',
                },
            ]
        },
        studentListItems() {
            if (!this.currentEvaluationResults) return []
            return this.currentEvaluationResults.students
        },
        // Evlauation states
        getSkillsPercentages() {
            return {
                locate: this.currentEvaluation
                    ? this.evaluationDetail?.classSkillsPercentages?.locate || 0
                    : 0,
                integrate: this.currentEvaluation
                    ? this.evaluationDetail?.classSkillsPercentages?.integrate || 0
                    : 0,
                reflect: this.currentEvaluation
                    ? this.evaluationDetail?.classSkillsPercentages?.reflect || 0
                    : 0,
                readingComprehension: this.currentEvaluation
                    ? this.evaluationDetail?.classSkillsPercentages?.readingComprehension || 0
                    : 0,
            }
        },
        evaluationToUpdate() {
            return this.selectedEvaluation
        },
        readingCategoryData() {
            const readingCategoryResults = this.readingCategoryOptions.map((category) => {
                return {
                    name: this.$t(`evaluate.evaluations.readingQuality.${category}`),
                    value:
                        this.studentListItems.length > 0
                            ? this.studentListItems.filter(
                                  (student) => student.reading_category === category
                              ).length
                            : 0,
                    itemStyle: {
                        color: '#17603C',
                    },
                }
            })

            readingCategoryResults.forEach(
                (result) =>
                    (result.itemStyle.color =
                        this.resultsColors[readingCategoryResults.indexOf(result)])
            )
            return readingCategoryResults
        },
        readingSpeedResults() {
            const readingSpeedResults = this.readingSpeedOptions.map((speed) => {
                return {
                    name: this.$t(`evaluate.evaluations.readingSpeed.${speed}`),
                    value:
                        this.studentListItems.length > 0
                            ? this.studentListItems.filter(
                                  (student) => student.fluency_level === speed
                              ).length
                            : 0,
                    itemStyle: {
                        color: '#17603C',
                    },
                }
            })
            readingSpeedResults.forEach(
                (speed) =>
                    (speed.itemStyle.color = this.resultsColors[readingSpeedResults.indexOf(speed)])
            )
            return readingSpeedResults
        },
        getEvalutionTextsWithResults() {
            if (
                !this.courseEvaluationRestulsTexts ||
                this.courseEvaluationRestulsTexts.length === 0
            ) {
                this.currentText = 0
                return []
            }

            const results = this.evaluationsTexts.filter((text) => {
                return this.courseEvaluationRestulsTexts.some(
                    (courseText) => courseText.id === text.id
                )
            })

            if (results.length > 0) {
                if (this.evaluationTextSelected) {
                    this.currentText = this.evaluationTextSelected
                } else {
                    this.currentText = results[0].id
                }
            }

            return results
        },
    },
    methods: {
        ...mapMutations('fluency', ['setCurrentCourseResultsID', 'setCurrentCourseTextResultsID']),
        ...mapActions('fluency', [
            'getResultsFromEvaluationText',
            'getEvaluationTexts',
            'getResultsFromEvaluation',
        ]),
        async generateExcel() {
            try {
                this.isDownloading = true

                this.isDownloading = false
            } catch (error) {
                this.isDownloading = false
            }
        },
        async generatePDF() {
            try {
                this.isDownloading = true

                this.isDownloading = false
            } catch (error) {
                this.isDownloading = false
            }
        },
        handleGoBackToAssignedEvaluations() {
            this.$router.push('/teacher/diagnoses/assigned')
        },
        async getCourseEvaluationResultsTexts() {
            this.loading = true
            const data = await FluencyServices.getCourseEvaluationResultsTexts(this.currentCourse)
            if (!data) {
                this.courseEvaluationRestulsTexts = []
                this.loading = false
                return
            }
            this.courseEvaluationRestulsTexts = data.evaluation_texts
            this.loading = false
        },
        async getEvaluationTextResults() {
            this.loading = true
            if (this.optionalCurrentEvaluationId) {
                this.currentEvaluationResults = await this.getResultsFromEvaluation({
                    evaluationID: this.optionalCurrentEvaluationId,
                    teacherID: this.userID,
                })
            } else {
                this.currentEvaluationResults = await this.getResultsFromEvaluationText({
                    courseId: this.currentCourse,
                    evaluationTextID: this.currentText,
                })
            }
            this.loading = false
        },
        getPercentageOfTotalAmount(studentsAmount) {
            return ((studentsAmount / this.studentListItems.length) * 100).toFixed(1)
        },
    },
    watch: {
        async currentCourse(courseID) {
            this.setCurrentCourseResultsID({ courseID })
            await this.getCourseEvaluationResultsTexts()
            if (this.optionalCurrentEvaluationId) {
                this.optionalCurrentEvaluationId = null
                this.$router.push({ query: { evaluationId: null } })
            }
        },
        async currentText(textID) {
            this.setCurrentCourseTextResultsID({ textID })

            if (!this.currentEvaluationResults || this.currentEvaluationResults.text_id !== textID) {
                await this.getEvaluationTextResults()
                if (this.optionalCurrentEvaluationId) {
                    this.optionalCurrentEvaluationId = null
                    this.$router.push({ query: { evaluationId: null } })
                }
            }
        },
        async currentEvaluation(evaluation) {
            if (!evaluation) return
            await this.getDiagnosis()
        },
    },
    async beforeMount() {
        this.loading = true
        if (this.evaluationsTexts.length === 0) {
            await this.getEvaluationTexts()
        }

        this.optionalCurrentEvaluationId = this.$route.query.evaluationId

        if (this.evaluationTextSelected) {
            this.currentText = this.evaluationTextSelected
        } else {
            this.currentText = this.evaluationsTexts[0].id
        }

        this.courseOptions = this.courses
            .filter((course) =>
                course.grade !== 'G0'
                && this.assignedEvaluationsState.find(e => e.course_id === course.id)?.course_id
            ).sort((a, b) => {
                if (a.ordinalGrade < b.ordinalGrade) return -1
                else if (a.ordinalGrade > b.ordinalGrade) return 1
                else {
                    // grades are equal, compare by section
                    if (a.section < b.section) return -1
                    else if (a.section > b.section) return 1
                    else return 0
                }
            })

        if (this.courseSelected) {
            this.currentCourse = this.courseSelected
        } else {
            this.currentCourse = this.courseOptions[0].id
        }

        await this.getCourseEvaluationResultsTexts()

        await this.getEvaluationTextResults()
        this.loading = false
    },
}
</script>
